import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import '/client/app/styles/text.scss';
import AddContentButton from '/client/app/components/addContentButton/addContentButtonContainer';
import CollapsingSidebarLayout from '/client/app/components/layouts/collapsingSidebarLayout/collapsingSidebarLayout';
import MultiSubSidebarContainer from '/client/app/components/communitySidebar/multiSubSidebarContainer';

const FeedContainer = loadable(() => import('/client/app/components/feed/feedContainer'));

export default function SubView({ communityNames }) {
	const communityTitle = communityNames.sort().join(' · ');

	return (
		<>
			<div className="mainTitle">{communityTitle}</div>
			<CollapsingSidebarLayout>
				<FeedContainer communityNames={communityNames} />
				<MultiSubSidebarContainer subNames={communityNames} />
			</CollapsingSidebarLayout>
			<AddContentButton community={communityNames.length === 1 ? communityNames[0] : null} />
		</>
	);
}

SubView.propTypes = {
	communityNames: PropTypes.arrayOf(PropTypes.string),
};

SubView.defaultProps = {
	communityNames: [],
};
