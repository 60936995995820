import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { faPlus, faEdit, faUsers } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

config.autoAddCss = false;

require('./addContentButton.scss');

function armIfSpaceOrEnter(e: React.KeyboardEvent<HTMLDivElement>, arm: Function) {
	console.log(`Char code is ${e.charCode}`);
	if (e.charCode === 13 || e.charCode === 32) {
		arm();
		e.preventDefault();
	}
}

type AddContentButtonProps = {
	isArmed: boolean;
	arm: Function;
};

export function AddContentButton({ isArmed, arm }: AddContentButtonProps) {
	return (
		<CSSTransition in={!isArmed} appear timeout={300} classNames="addContentButton" unmountOnExit>
			<div
				className="addContentButton"
				role="button"
				tabIndex={0}
				onKeyPress={e => armIfSpaceOrEnter(e, arm)}
				onClick={() => arm()}
			>
				<FontAwesomeIcon icon={faPlus} size="2x" fixedWidth title="new Content" />
			</div>
		</CSSTransition>
	);
}

AddContentButton.propTypes = {
	isArmed: PropTypes.bool,
	arm: PropTypes.func.isRequired,
};

AddContentButton.defaultProps = {
	isArmed: false,
};

type AddPostButtonProps = {
	isArmed: boolean;
};

export function AddPostButton({ isArmed }: AddPostButtonProps) {
	return (
		<CSSTransition in={isArmed} appear timeout={300} classNames="addContentButton" unmountOnExit>
			<div className="addContentButton">
				<FontAwesomeIcon icon={faEdit} size="2x" fixedWidth title="new post" />
			</div>
		</CSSTransition>
	);
}

AddPostButton.propTypes = {
	isArmed: PropTypes.bool,
};

AddPostButton.defaultProps = {
	isArmed: false,
};

type AddSubButtonProps = {
	isArmed: boolean;
};

export function AddSubButton({ isArmed }: AddSubButtonProps) {
	return (
		<CSSTransition in={isArmed} appear timeout={340} classNames="addSubButton" unmountOnExit>
			<div className="addSubButton" key="addSubButton" title="new community">
				<FontAwesomeIcon icon={faUsers} fixedWidth size="2x" />
			</div>
		</CSSTransition>
	);
}

AddSubButton.propTypes = {
	isArmed: PropTypes.bool,
};

AddSubButton.defaultProps = {
	isArmed: false,
};
