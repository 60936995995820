import React, { createRef } from 'react';
import { Link } from 'react-router-dom';
import { AddContentButton, AddPostButton, AddSubButton } from './addContentButton';

require('./addContentButton.scss');

type IProps = {
	community?: String;
};

type IState = {
	isArmed: boolean;
	isHovered: boolean;
};

interface HTMLElementEvent extends Event {
	target: HTMLElement & EventTarget;
}

export default class AddContentButtonContainer extends React.Component<IProps, IState> {
	private ref = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);
		this.arm = this.arm.bind(this);
		this.disarm = this.disarm.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.engageDisarm = this.engageDisarm.bind(this);
		this.shouldDisarm = this.shouldDisarm.bind(this);
		this.shouldTouchDisarm = this.shouldTouchDisarm.bind(this);
		this.state = {
			isArmed: false,
			isHovered: false,
		};
	}

	componentDidMount() {
		window.addEventListener('touchstart', this.shouldTouchDisarm, false);
	}

	componentWillUnmount() {
		window.removeEventListener('touchstart', this.shouldTouchDisarm, false);
	}

	arm() {
		this.setState(prevState => ({
			...prevState,
			isArmed: true,
		}));
	}

	disarm() {
		this.setState(prevState => ({
			...prevState,
			isArmed: false,
		}));
	}

	onMouseEnter() {
		this.setState(prevState => ({
			...prevState,
			isHovered: true,
		}));
	}

	engageDisarm() {
		this.setState(prevState => ({
			...prevState,
			isHovered: false,
		}));
		setTimeout(this.shouldDisarm, 3000);
	}

	shouldDisarm() {
		const { isHovered } = this.state;
		if (!isHovered) {
			this.disarm();
		}
	}

	shouldTouchDisarm(event: HTMLElementEvent | Event) {
		const currentRef = this.ref.current;
		if (currentRef && !currentRef.contains((event as HTMLElementEvent).target)) {
			this.disarm();
		}
	}

	render() {
		const { isArmed } = this.state;
		const { community } = this.props;
		const createPostURL = community ? `/createPost?community=${community}` : '/createPost';
		return (
			<div
				className="addContentButtonContainer"
				onMouseLeave={this.engageDisarm}
				ref={this.ref}
				onMouseEnter={this.onMouseEnter}
			>
				<AddContentButton arm={this.arm} isArmed={isArmed} />

				<Link title="create post" to={createPostURL}>
					<AddPostButton isArmed={isArmed} />
				</Link>
				<Link title="create community" to="/createCommunity">
					<AddSubButton isArmed={isArmed} />
				</Link>
			</div>
		);
	}
}
