import React from 'react';
import PropTypes from 'prop-types';
import Sub from './sub';

function SubContainer(props) {
	const { urlParameters } = props;
	const communityNames = urlParameters.sub.split('+');
	return <Sub communityNames={communityNames} {...props} />;
}

SubContainer.propTypes = {
	urlParameters: PropTypes.shape({
		sub: PropTypes.string,
	}).isRequired,
};

export default SubContainer;
